import { db } from "../services/firebase";

export function _createRoom(owner, roomName, movieName, movieFile, movieLink) {
  return db.ref('room').push({
      owner,
      roomName,
      movieFile,
      movieName,
      movieLink,
      created: Date.now(),
      state: {
        isPlaying: false,
        startTime: null,
        startAt: 0
      }
  });
}

export function _updateRoomState(room, state) {
    return db.ref('room/' + room + '/state').update({
        isPlaying: state.isPlaying,
        startTime: state.startTime,
        startAt: state.startAt
    });
}

export function _deleteRoom(room) {
    return db.ref('room/' + room).remove();
}