import React from 'react';
import { Link } from 'react-router-dom';
import { _signInWithGoogle, _signInWithGitHub } from '../helpers/auth';
import { ToastContainer, toast } from 'react-toastify';
import { auth } from "../services/firebase";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: auth().currentUser,
      email: '',
      password: '',
      remember: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.googleSignIn = this.googleSignIn.bind(this);
    this.githubSignIn = this.githubSignIn.bind(this);

    this.signinRedirect = this.signinRedirect.bind(this);
  }

  componentDidMount() {
    if(this.state.user !== null)
      this.signinRedirect();
  }

  handleChange(event) {
    const target = event.target;
    const value = target.name === 'remember' ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value });
  }

  async handleSubmit(event) {
    if(event)
      event.preventDefault();

    const persistence = (this.state.remember) ? auth.Auth.Persistence.LOCAL : auth.Auth.Persistence.SESSION;
    
    auth().setPersistence(persistence).then(() => {
        auth().signInWithEmailAndPassword(this.state.email, this.state.password)
        .then((result) => {
          this.signinRedirect();
        }).catch((error)  => {
          console.log(error);
          toast.error('Could not log in. Username and/or password may be incorrect.');
        });
    });
  }

  async googleSignIn() {
      try {
          await _signInWithGoogle();
          this.signinRedirect();
      } catch (error) {
          toast.error(error.message);
      }
  }
  async githubSignIn() {
      try {
          await _signInWithGitHub();
          this.signinRedirect();
      } catch (error) {
          toast.error(error.message);
      }
  }

  signinRedirect() {
    setTimeout(() => { 
      if(this.props.location.state !== undefined 
        && this.props.location.state !== 'undefined'
        && this.props.location.state.from.pathname !== '/login'
        && this.props.location.state.from.pathname !== '/signup'
      )
          this.props.history.push(this.props.location.state.from.pathname);
      else
          this.props.history.push('/room');
    }, 500);
  }

  render() {
    const from = (this.props.location.state !== undefined) ? this.props.location.state.from.pathname : '/';

    return (
      <section className="login grid box">
        <ToastContainer />
        <article className="grid-col">
          <h1 className="header">Login</h1>
          <p className="sub-header">Don't have an account? <Link to={{ pathname: '/signup', state: { from: from } }}>Click here</Link>  to signup for a free account</p>
          <form autoComplete="off" onSubmit={this.handleSubmit}>
            <fieldset>
              <input
                  placeholder="Email"
                  name="email"
                  type="email"
                  onChange={this.handleChange}
                  value={this.state.email}
              />
              <input
                placeholder="Password"
                name="password"
                onChange={this.handleChange}
                value={this.state.password}
                type="password"
              />
              <label className="checkbox checkbox-small">Remember Me
                <input 
                  name="remember" 
                  type="checkbox" 
                  onChange={this.handleChange}
                  checked={this.state.remember}
                />
                <span className="checkmark">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="36px" height="36px"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/></svg>
                </span>
              </label>
            </fieldset>
            <fieldset>
              <button type="submit">Login</button>
            </fieldset>
            <hr></hr>
            <fieldset className="social">
              <button className="google" onClick={this.googleSignIn} type="button">
                  Connect with Google
              </button>
            </fieldset>
          </form>
        </article>
        <aside className="grid-col">
            <div className="message">
              <h2>Welcome Back</h2>
              <span className="spacer"></span>
              <h3>Login in to create a new room or open an old room</h3>
            </div>
        </aside>
      </section>
    );
  }
}

export default Login;