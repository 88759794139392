import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCbu3JzUqGZdN2gLWYLPzWD2B-ugPS43ns",
    authDomain: "sdmovies-fb506.firebaseapp.com",
    databaseURL: "https://sdmovies-fb506.firebaseio.com",
    projectId: "sdmovies-fb506",
    storageBucket: "sdmovies-fb506.appspot.com",
    messagingSenderId: "147256131351",
    appId: "1:147256131351:web:ba08279b00f5f62f180152"
};

if (process.env.NODE_ENV === 'development') {
    //firebaseConfig.databaseURL = 'http://localhost:9000/?ns=sdmovies-fb506';
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth;
export const db = firebase.database();