import React from 'react';
import { Link } from "react-router-dom";

import logo from '../images/sdmovies_logo_shadow.png';

class Home extends React.Component {
    render() {
        return (
            <section className="home">
                <div className="header">
                    <div className="message">
                        <h1>{process.env.REACT_APP_LNAME}</h1>
                        <h2>The Safe Way to Watch Movies with Your Friends</h2>
                        <Link to="/create">Create A New Room</Link>
                    </div>
                    <div className="image">
                        <img src={logo} alt={process.env.REACT_APP_SNAME} />
                    </div>
                </div>
                <div className="howto">
                    <div className="tab-container">
                        <div className="tab">
                            
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Home;