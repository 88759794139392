import React from 'react';
import Axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API } from '../services/api';
import { _deleteRoom } from "../helpers/db";

class Actions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            videoFileDownloading: false,
            videoFileDownloadProgress: 0,
            link: ''
        };
        this.axios =  Axios.create();

        this.downloadVideo = this.downloadVideo.bind(this);
        this.deleteRoom = this.deleteRoom.bind(this);
        this.copyVideoLink = this.copyVideoLink.bind(this);
    }

    componentDidMount() {
        let linkParts = window.location.href.split('/');
        let link = linkParts[0] + '//' + linkParts[2] + '/room/' + this.props.roomId;

        this.setState({link});
    }

    //Download Video
    downloadVideo() {
        if(this.props.movieLink !== '' && this.props.movieFile !== '') {
            let fileUrl = this.props.movieLink;
            let fileName = this.props.movieFile;

            // for non-IE
            if (!window.ActiveXObject) {
                var save = document.createElement('a');
                save.href = fileUrl;
                save.target = '_blank';
                save.download = fileName;

                if ( navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
                        document.location = save.href; 
                }else{
                    var evt = new MouseEvent('click', {
                        'view': window,
                        'bubbles': true,
                        'cancelable': false
                    });
                    save.dispatchEvent(evt);
                    (window.URL || window.webkitURL).revokeObjectURL(save.href);
                }
            }
            // for IE < 11
            else if ( !! window.ActiveXObject && document.execCommand) {
                var _window = window.open(fileUrl, '_blank');
                _window.document.close();
                _window.document.execCommand('SaveAs', true, fileName || fileUrl)
                _window.close();
            }
        } else {
            toast.error('Movie information not set');
        }
    }
    
    //Copy Video Link
    copyVideoLink() {
        console.log(this.state.link);

        const el = this.textArea;
        el.select();

        try {
            document.execCommand("copy");
            toast.success('Link copied to clipboard!');
        } catch(e) {
            toast.error('Could not copy to clipboard...');
        }

        if (window.getSelection) {window.getSelection().removeAllRanges();}
        else if (document.selection) {document.selection.empty();}
    }

    //Delete Room
    async deleteRoom() {
        if(this.props.roomId &&  this.props.roomId !== '') {
            await _deleteRoom(this.props.roomId).then((response) => {
                if(this.props.movieId && this.props.movieId !== '')
                this.axios({
                    method: 'delete',
                    url: API + 'video/' + this.props.movieId,
                })
                .then((response) => {
                    toast.success('Room has been deleted');
                    this.props.roomDeleted(this.props.roomId);
                })
                .catch((error) => {
                    toast.error('Room could not be deleted');
                });
            });
        }
    }

    render() {
        const theme = (this.props.theme && this.props.theme !== '') ? ' actions-' + this.props.theme : '';
        const alignment = (this.props.alignment) ? ' actions-align-' + this.props.alignment : '';

        return (
            <ul className={"actions" + alignment + theme}>
                <ToastContainer />
                {(this.props.showDelete) ?
                <li>
                    <button type="button" onClick={this.deleteRoom} title="Delete Room">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="36px" height="36px"><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                    </button>
                </li>
                : null}
                {(this.props.showDownloadOld) ?
                <li>
                    <button type="button" disabled={(this.state.videoFileDownloading) ? true : false} onClick={this.downloadVideo} title="Download Movie">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="36px" height="36px"><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                    </button>
                    <span className={'action-progress' + ((this.state.videoFileDownloading) ? '' : ' hidden')}>{this.state.videoFileDownloadProgress}%</span>
                </li>
                : null}
                {(this.props.showDownload) ?
                <li>
                    <a href={this.props.movieLink} download={this.props.movieFile} title="Download Movie">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="36px" height="36px"><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                    </a>
                </li>
                : null}
                {(this.props.showSelect) ?
                <li>
                    <button type="button" onClick={() => this.props.selectVideo()} title="Select Movie File">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="36px" height="36px"><path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                    </button>
                </li>
                : null}
                {(this.props.showCopy) ?
                <li>
                    <button type="button" onClick={this.copyVideoLink} title="Copy Room Link">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="36px" height="36px"><path d="M0 0h24v24H0z" fill="none"/><path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"/></svg>
                    </button>
                    <textarea className="sr-only" ref={(textarea) => this.textArea = textarea} defaultValue={this.state.link}/>
                </li>
                : null}
            </ul>
        );
    }
}

export default Actions;