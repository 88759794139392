import React from 'react';
import Helmet from 'react-helmet';
import Popup from 'reactjs-popup';
import DropboxChooser from 'react-dropbox-chooser';
import GooglePicker from 'react-google-picker';
import { auth } from '../services/firebase';
import { _createRoom } from '../helpers/db';
import PreMarker from '../components/PreMarker';

import iconDropbox from '../images/dropbox-reverse-icon.png';
import iconGDrive from '../images/googledrive-icon.png';
import iconLink from '../images/link-icon.png';

class Room extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: auth().currentUser,
            roomName: '',
            movieName: '',
            movieFileLink: '',
            movieFileName: '',
            popupMovieFileLink: '',
            popupMovieFileName: '',
            writeError: null,
            dropboxAppKey: 'sxdr5h4oanb9pur',
            gdriveClientId: '132682717404-dj6hf7ocirlcosq0lgt2jabb02cl1urs.apps.googleusercontent.com',
            gdriveDeveloperKey: 'AIzaSyCVJ9W1_bHUHWbekZeEVXzNJ4V_S8CqXeY',
            showLinkPopup: false
        };
        
        this.handleChange = this.handleChange.bind(this);
        this.createRoom = this.createRoom.bind(this);

        this.handleDopboxSelect = this.handleDopboxSelect.bind(this);
        this.handleGDriveSelect = this.handleGDriveSelect.bind(this);
        this.handleLinkSelect = this.handleLinkSelect.bind(this);
        this.handleShowLinkPopup = this.handleShowLinkPopup.bind(this);
        this.handleHideLinkPopup = this.handleHideLinkPopup.bind(this);
    }

    async createRoom(event) {
        event.preventDefault();

        this.setState({ writeError: null });
        
        const owner = this.state.user.uid;
        try {
            const result = await _createRoom(
                owner, 
                this.state.roomName, 
                this.state.movieName,
                this.state.movieFileName,
                this.state.movieFileLink
            );
            this.props.history.push('/room/' + result.key);
        } catch (error) {
            console.log(error.message);
            this.setState({ writeError: error.message });
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleDopboxSelect(files) {
        console.log(files);

        const dropboxVideoLink = files[0].link.replace('?dl=0', '?dl=1');
        const dropboxVideoName = files[0].name;

        this.setState({ 
            movieFileLink: dropboxVideoLink, 
            movieFileName: dropboxVideoName 
        });
    }

    handleGDriveSelect(files) {
        if(files && files.length > 0) {
            console.log(files);

            const gdriveVideoLink = 'https://drive.google.com/uc?export=download&id=' + files[0].id;
            const gdriveVideoName = files[0].name;

            this.setState({ 
                movieFileLink: gdriveVideoLink, 
                movieFileName: gdriveVideoName 
            });
        }
    }

    handleLinkSelect() {
        this.setState({ 
            movieFileLink: this.state.popupMovieFileLink, 
            movieFileName: this.state.popupMovieFileName,
            showLinkPopup: false
        });
    }

    handleShowLinkPopup() {
        this.setState({ showLinkPopup: true });
    }
    handleHideLinkPopup() {
        this.setState({ showLinkPopup: false });
    }

    render() {
        let canSubmit = (this.state.roomName !== '' && this.state.movieName !== '' && this.state.movieFileLink) ? true : false;
        return (
            <section className="room-create box">
                <Helmet>
                    <title>Social Distancing Movies | Create A Room</title>
                </Helmet>
                <form autoComplete="off" onSubmit={this.createRoom}>
                    <fieldset className="create">
                        <h1>Create A Room</h1>
                        <input placeholder="Your Room Name" name="roomName" type="text" onChange={this.handleChange} value={this.state.roomName} />
                        <label className="label-sup" htmlFor="roomName">Room Name</label>
                        <input placeholder="Name of the Movie" name="movieName" type="text" onChange={this.handleChange} value={this.state.movieName} />
                        <label className="label-sup" htmlFor="movieName">Movie Name</label>
                        <div className="movieFile">
                            <label className="label-sup">Movie File</label>
                            <p className="sudoInput">{(this.state.movieFileName !== '') ? this.state.movieFileName : 'Add Movie File Below'}</p>
                            <div className="movieFile-select">
                                <div className="movieFile-select-item">
                                    <DropboxChooser 
                                        appKey={this.state.dropboxAppKey}
                                        success={files => this.handleDopboxSelect(files)}
                                        cancel={() => console.log('closed')}
                                        multiselect={false}
                                        extensions={['.mp4']} 
                                    >
                                        <img title="Dropbox" src={iconDropbox} alt="Select from Dropbox" />       
                                    </DropboxChooser>
                                </div>
                                <div className="movieFile-select-item">
                                    <GooglePicker 
                                        clientId={this.state.gdriveClientId}
                                        developerKey={this.state.gdriveDeveloperKey}
                                        scope={['https://www.googleapis.com/auth/drive.readonly']}
                                        onChange={data => this.handleGDriveSelect(data.docs)}
                                        onAuthFailed={data => console.log('on auth failed:', data)}
                                        multiselect={false}
                                        navHidden={false}
                                        authImmediate={false}
                                        mimeTypes={['video/mp4']} 
                                    >
                                        <img title="Google Drive" src={iconGDrive} alt="Select from Google Drive" />    
                                    </GooglePicker>
                                </div>
                                <div className="movieFile-select-item">
                                    <img title="Link" src={iconLink} alt="Select from Link" onClick={this.handleShowLinkPopup} />
                                    <Popup open={this.state.showLinkPopup} modal>
                                        <div className="modal-wrapper">
                                            <div className="modal" tabIndex="-1" role="dialog">
                                                <div className="modal-dialog" role="document">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h1>Link to Movie</h1>
                                                            <button type="button" className="close" onClick={this.handleHideLinkPopup} data-dismiss="modal" aria-label="Close">
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <input placeholder="Link to Download Movie" name="popupMovieFileLink" type="text" onChange={this.handleChange} value={this.state.popupMovieFileLink} />
                                                            <label className="label-sup" htmlFor="popupMovieFileLink">Movie Download Link <span className="label-note">*must be .mp4</span></label>
                                                            <input placeholder="Name of the Movie" name="popupMovieFileName" type="text" onChange={this.handleChange} value={this.state.popupMovieFileName} />
                                                            <label className="label-sup" htmlFor="popupMovieFileName">Movie File Name (moviename.mp4)</label>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn" onClick={this.handleLinkSelect}>Add Link to Movie</button>
                                                            <button type="button" className="btn-secondary" onClick={this.handleHideLinkPopup} data-dismiss="modal">Close</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Popup>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset className="details">
                        <h1>Progress</h1>
                        <ul>
                            <li>
                                <PreMarker complete={this.state.roomName !== ''} />
                                <p>Room Name: {this.state.roomName}</p>
                            </li>
                            <li>
                                <PreMarker complete={this.state.movieName !== ''} />
                                <p>Movie Name: {this.state.movieName}</p>
                            </li>
                            <li>
                                <PreMarker complete={this.state.movieFileName !== ''} />
                                <p>Movie File: {this.state.movieFileName}</p>
                            </li>
                        </ul>
                        <button type="submit" onClick={this.createRoom} disabled={!canSubmit}>Create Room</button> 
                    </fieldset>
                </form>
            </section>
        );
    }
}

export default Room;