import React from 'react';
import { Link } from 'react-router-dom';
import { _signInWithGoogle, _signInWithGitHub } from '../helpers/auth';
import { ToastContainer, toast } from 'react-toastify';
import { auth } from "../services/firebase";

class Signup extends React.Component {
  constructor() {
    super();
    this.state = {
      error: null,
      email: '',
      password: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.signinRedirect = this.signinRedirect.bind(this);

    this.googleSignIn = this.googleSignIn.bind(this);
    this.githubSignIn = this.githubSignIn.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }
  async handleSubmit(event) {
    if(event)
      event.preventDefault();

    let email = this.state.email;
    let pass = this.state.password;
    
    auth().createUserWithEmailAndPassword(email, pass)
    .then((result) => {
      this.signinRedirect();
    }).catch((error)  => {
      console.log(error);
      toast.error('There was an error while signing up. Please try again.');
    });
  }

  async googleSignIn() {
      try {
          await _signInWithGoogle();
          this.signinRedirect();
      } catch (error) {
          toast.error(error.message);
      }
  }
  async githubSignIn() {
      try {
          await _signInWithGitHub();
          this.signinRedirect();
      } catch (error) {
          toast.error(error.message);
      }
  }

  signinRedirect() {
    console.log('Redirecting...');

    setTimeout(() => { 
      if(this.props.location.state
        && this.props.location.state !== undefined 
        && this.props.location.state !== 'undefined'
        && this.props.location.state.from.pathname !== '/login'
        && this.props.location.state.from.pathname !== '/signup'
      )
          this.props.history.push(this.props.location.state.from.pathname);
      else
          this.props.history.push('/room');
    }, 500);
  }

  render() {
    const from = (this.props.location.state !== undefined) ? this.props.location.state.from.pathname : '/room';

    return (
      <section className="login grid box">
        <ToastContainer />
        <article className="grid-col">
          <h1 className="header">Signup</h1>
          <p className="sub-header">Already have an account? <Link to={{ pathname: '/login', state: { from: from } }}>Click here</Link> to login to your account</p>
          <form autoComplete="off" onSubmit={this.handleSubmit}>
            <fieldset>
              <input
                  placeholder="Email"
                  name="email"
                  type="email"
                  onChange={this.handleChange}
                  value={this.state.email}
              />
              <input
                placeholder="Password"
                name="password"
                onChange={this.handleChange}
                value={this.state.password}
                type="password"
              />
            </fieldset>
            <fieldset>
              <button type="submit">Signup</button>
            </fieldset>
            <hr></hr>
            <fieldset className="social">
              <button className="google" onClick={this.googleSignIn} type="button">
                  Connect with Google
              </button>
            </fieldset>
          </form>
        </article>
        <aside className="grid-col">
            <div className="message">
              <h2>Welcome</h2>
              <span className="spacer"></span>
              <h3>Signing up will allow you to create new rooms and share them with your friends</h3>
            </div>
        </aside>
      </section>
    )
  }
}

export default Signup;