import React from 'react';
import Helmet from 'react-helmet';
import { Link } from "react-router-dom";
import { auth } from "../services/firebase";
import { db } from "../services/firebase";

import Actions from '../components/Actions';

class Rooms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: auth().currentUser,
            rooms: null,
            sort: 'created',
            sortType: 'date',
            sortOrder: 'desc',
            locale: 'en-US'
        };

        this.sortValuesByType = this.sortValuesByType.bind(this);
        this.getExpirationDate = this.getExpirationDate.bind(this);
    }

    componentDidMount() {
        //Check Owner of Room
        const roomdb = db.ref('room');
        roomdb.orderByChild("owner").equalTo(this.state.user.uid).on("value", (snapshot) => {
            let rooms = [];
            snapshot.forEach((childSnapshot) => {
                const childKey = childSnapshot.key;
                const childData = childSnapshot.val();

                const todaysDate = Date.now();
                const expirationDate = this.getExpirationDate(childData.created);

                if(expirationDate.getTime() >= todaysDate)
                    rooms.push({'key':childKey, ...childData});
            });

            this.setState({ rooms });
        });
    }

    handleRoomDeleted() { 
        //No Action required on delete 
    }

    sortValuesByType(sortA,sortB) {
        let result = 0;
        let a = sortA;
        let b = sortB;

        //Reverse Values if Descending Order
        if(this.state.sortOrder === 'desc') {
            a = sortB;
            b = sortA;
        }

        //Sort By Type
        switch(this.state.sortType) {
            case 'date':
                let dateA = new Date(a);
                let dateB = new Date(b);
                result = ((dateA > dateB) ? 1 : ((dateB > dateA) ? -1 : 0));
                break;
            case 'string':
                result = ((a > b) ? 1 : ((b > a) ? -1 : 0));
                break;
            default:
                result = a-b;
        }

        return result;
    }

    getExpirationDate(createdDate) {
        let lifespan =  (parseInt(process.env.REACT_APP_ROOM_LIFESPAN) * 86400000);
        let expirationDate = new Date(createdDate + lifespan);

        return expirationDate;
    }

    render() {
        let roomsList = this.state.rooms;
        let rooms = [];
        if(roomsList !== null) {
            if(this.state.rooms.length > 0) {
                roomsList.sort((a,b) => { return this.sortValuesByType(a[this.state.sort],b[this.state.sort]); });
                
                rooms = roomsList.map((room) =>
                    <div key={room.key} className="list-item">
                        <div className="list-item-col">
                            <Link className="btn room-link" to={'/room/' + room.key}>View</Link>
                        </div>
                        <div className="col-details list-item-col list-item-col-3">
                            <span className="list-item-col-header">Room Details</span>
                            <h3>{room.roomName}</h3>
                            <p>{room.movieName}</p>
                        </div>
                        <div className="list-item-col">
                            <span className="list-item-col-header">Created On</span>
                            <p title={new Date(room.created).toLocaleString(this.state.locale)}>{new Date(room.created).toLocaleDateString(this.state.locale)}</p>
                            <p className="room-exp">Expires: {this.getExpirationDate(room.created).toLocaleDateString(this.state.locale)}</p>
                        </div>
                        <div className="list-item-col">
                            <span className="list-item-col-header">Actions</span>
                            <Actions
                                alignment="center"
                                theme="dark"
                                roomId={room.key}
                                movieLink={room.movieLink}
                                movieFile={room.movieFile}
                                showDelete={true} 
                                showDownload={true} 
                                showSelect={false} 
                                showCopy={true}
                                roomDeleted={this.handleRoomDeleted}
                            />
                        </div>
                    </div>
                );
            } else {
                rooms.push(
                    <div className="empty" key="empty">
                        <h2>You have not created any rooms yet :( </h2>
                        <Link to="/create">Click here to make it less empty</Link>
                    </div>
                );
            }
        }

        return (
            <section className="rooms room">
                <Helmet>
                    <title>Social Distancing Movies | My Rooms</title>
                </Helmet>
                <div className="room-header">
                    <h1>My Rooms</h1>
                    <div className="actions">
                        <Link to="/create">+ Create A New Room</Link>
                    </div>
                </div>
                {rooms}
            </section>
        );
      }
}

export default Rooms;