import React from "react";
import videojs from 'video.js'

import 'video.js/dist/video-js.css';

const defaultOptions = {
    autoplay: false,
    controls: true,
    preload: 'true',
    aspectRatio: '16:9',
    controlBar: {
        pictureInPictureToggle: false
    }
}
const defuaultViewerOptions = {
    bigPlayButton: false,
    controlBar: {
        playToggle: false,
        progressControl: {
            seekBar: true
        },
        playbackRateMenuButton: false,
        pictureInPictureToggle: false
    }
};

class Player extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            videoReady: false
        };
        this.player = null;

        this.onReady = this.onReady.bind(this);
        this.isPlaying = this.isPlaying.bind(this);
        this.getVideoTime = this.getVideoTime.bind(this);
    }

    componentDidMount() {
        let videoJSOptions = {...defaultOptions, ...this.props};
        if(!this.props.userIsOwner)
            videoJSOptions = {...videoJSOptions, ...defuaultViewerOptions}
            
        this.player = videojs(this.videoNode, videoJSOptions, this.onReady);
    }
    componentWillUnmount() {
        if (this.player)
            this.player.dispose()
    }

    onReady() {
        //Begin Loading the Src
        this.player.load();

        if(!this.props.userIsOwner) {
            this.player.controlBar.progressControl.disable();
        } else {
            this.player.on("play", () => {
                this.props.videoPlayed();
            });
            this.player.on("pause", () => {
                this.props.videoPaused();
            });
            this.player.on("seeked", () => {
                this.props.videoSeeked();
            });
            this.player.on("ended", () => {
                this.player.setCurrentTime(0);
                this.player.pause();
                
                this.props.videoPaused();
            });
        }

        this.setState({videoReady: true});
    }

    isPlaying() {
        return !this.player.paused();
    }
    getVideoTime() {
        return this.player.currentTime();
    }
    
    render() {
        return (
            <div className="video-js-container">
                <div data-vjs-player>
                    <script>window.HELP_IMPROVE_VIDEOJS = false;</script> 
                    <video ref={ node => this.videoNode = node } className="video-js vjs-has-started"></video>
                </div>
            </div>
        )
    }
}
export default Player;