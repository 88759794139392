import React from 'react';
import Uppy from '@uppy/core';
import Tus from '@uppy/tus';
import Axios from 'axios';
import fileDownload  from 'js-file-download';
import DropboxChooser from 'react-dropbox-chooser';
import GooglePicker from 'react-google-picker';
//import { GraphFileBrowser } from '@microsoft/file-browser';
import { DragDrop, StatusBar } from '@uppy/react';
import { ToastContainer, toast } from 'react-toastify';
import { auth } from '../services/firebase';
import { _createRoom } from '../helpers/db';

import '@uppy/core/dist/style.css';
import '@uppy/status-bar/dist/style.css';
import '@uppy/drag-drop/dist/style.css';


//const API = 'http://sdmovies.servebeer.com:1080/';
const API = 'http://192.168.0.239:1080/';
const videos = [
    '93a3c1e81879a39d5ec9606178990291',
    '379d57945b7a6b9c39f2dc64b9a5f264',
    'b2e82ccd2ba8ceb5b7eb412d0bf7cb42',
];

class Test extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: auth().currentUser,
            section: 'cloud',
            roomName: '',
            movieName: '',
            downloadVideo: '93a3c1e81879a39d5ec9606178990291',
            downloadProgress: 0,
            downloadBlob: '',
            videoFile: null,
            videoFileType: 'video/*',
            videoFileURL: '',
            writeError: null,
            dropboxAppKey: 'sxdr5h4oanb9pur',
            dropboxVideoLink: '',
            dropboxVideoName: '',
            gdriveClientId: '132682717404-dj6hf7ocirlcosq0lgt2jabb02cl1urs.apps.googleusercontent.com',
            gdriveDeveloperKey: 'AIzaSyCVJ9W1_bHUHWbekZeEVXzNJ4V_S8CqXeY',
            gdriveVideoLink: '',
            gdriveVideoName: '',
            onedriveAppKey: 'sxdr5h4oanb9pur',
            onedriveVideoLink: '',
            onedriveVideoName: '',
        };

        this.uppy = Uppy()
        .use(Tus, {
            endpoint: API + 'uploads/',
        }).on('complete', (result) => {
            console.log('Upload complete! We’ve uploaded these files:', result.successful)
        });

        this.axios =  Axios.create({
            baseURL: API,
        });

        this.handleChange = this.handleChange.bind(this);
        this.createRoom = this.createRoom.bind(this);
        this.downloadTorrent = this.downloadTorrent.bind(this);

        this.handleFileLocationChange = this.handleFileLocationChange.bind(this);

        this.handleDopboxSelect = this.handleDopboxSelect.bind(this);
        this.handleGDriveSelect = this.handleGDriveSelect.bind(this);
        this.getOneDriveAuthenticationToken = this.getOneDriveAuthenticationToken.bind(this);
    }

    componentWillUnmount () {
        this.uppy.close();
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleFileLocationChange(event) {
       const videoFileURL = URL.createObjectURL(event.target.files[0]);
       this.setState({videoFileURL});
    }

    handleDopboxSelect(files) {
        console.log(files);

        const dropboxVideoLink = files[0].link.replace('?dl=0', '?dl=1');
        const dropboxVideoName = files[0].name;

        this.setState({ dropboxVideoLink, dropboxVideoName });
    }

    handleGDriveSelect(files) {
        if(files && files.length > 0) {
            console.log(files);

            const gdriveVideoLink = 'https://drive.google.com/uc?export=download&id=' + files[0].id;
            const gdriveVideoName = files[0].name;

            this.setState({ gdriveVideoLink, gdriveVideoName });
        }
    }

    getOneDriveAuthenticationToken() {
        return 'EwBwA8l6BAAU6k7+XVQzkGyMv7VHB/h4cHbJYRAAAZbqgcJ8JyF2o1qUwkXlfybrpZ5vQvIDENboheb48UuDhkOEoTvr6lfhN2xPJx2eYQTSZ/HLgOvZtoBEfv6BC/eseqBwCKIyHhynJBIrAPsa9N8bM+XOvGHZ2BWCIbBMjZ7WRP8RVhEogmcjHqBfuPHng8TBqLuDRJZnegu3oroUHOoPRwUsTyBkEP1mwuFhiz6g3XoYZEsmeUDNXGAxzc+wm1ngPACldqGeUtMIewuhGB1I1PmNkbA4eB37iI/wsfc1K9GcGWW/ksfi3FWzsosoH2W9ake/xhWmC2Dko8+9kRr6gi6Q4c+WbsHA/0EXWVZOM9hRuvfkVn4LTwI3YBQDZgAACK/Hmwv6rXRUQALH3sXoDeCbxnjeBNw3KiAxsBgcQk/pEVAFiAXCz5hFaNyv4O1rwDxnll82uF1o0S6CxPlOQUJk0Es4pd6NwJnmK3iWaLPoBKw1O+f25rv6bhkiWcY84B+Kyzn9DeIqPLP5NZ0oXHjlOhD404P9qqrCKOKhEhgHRJrDyClYjxEN3hp3hD1226txXO+LWBtmF+hhBO3h6v8H/MKOX5IpozISbu31Eg4e2mwX0O54mF4v+8w8Yc6wn/G4ekZLLfdQxRnf2/g4JbJQXbpLAocIkkgK76tVhH7Ke+fg7dlt+WNjYPTm6SwcR2gOWajg3qD/S/nxDJlucmQkKszbRSju7mOnLLpLFCH2Y8X+VMe1O3jz2oX7PChFVs7Qs/9ydSPHMMgs0AxlW05/00xBFb3GrfIEEeqdJL61e1oGRwndFAYSe74oSPD0fbilZwx581VMgBICCOo6Ajxsjkh67zXd4oJ37C+qGQbOsPJVlGhW1ZQ4X7QZjTTPMzmUkC3FCyIASTFmOV8OC7FLBfbZ55Bl8WPPJ9d8NDJQ2j2pDmiMyiP2UUtLw7JC70hzQB54xKCfAfVqPXamYN6SXdru+nXVs24lht5c868fCE6IBx59nCAJftYr77NP3hmWYdZQjlWNhVkcuhZv2m68KxyGF51XR5Bg6Vwt12kKSL2onIJ+mE/QQEk0E0XtDDGdY6Sl1flJV8K6lyWq9bI/gjFCzdvJlgBhCqdNi2rrEFlvqbMCdetdXbSfN73R5CCjy7Pkn+FtuAKFAg==';
    }

    async downloadTorrent() {
        if(this.state.downloadVideo !== '') {
            this.axios({
                method: 'get',
                url: '/download/' + this.state.downloadVideo,
                responseType: 'blob',
                onDownloadProgress: (progressEvent) => {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    this.setState({downloadProgress: percentCompleted});
                }
            })
            .then((response) => {
                const videoFileURL = URL.createObjectURL(response.data);
                this.setState({ 
                    videoFileURL,
                    downloadProgress: 0
                });
                fileDownload(response.data, this.state.downloadVideo + '.mp4');

            });
        } else {
            console.log('No video provided....');
        }
    }

    async createRoom(event) {
        event.preventDefault();

        this.setState({ writeError: null });
        
        const owner = this.state.user.uid;
        try {
            const result = await _createRoom(
                owner, 
                this.state.roomName, 
                '',
                this.state.movieName,
                ''
            );

            toast.success('Room created! Room Key: ' + result.key);
        } catch (error) {
            console.log(error.message);
            this.setState({ writeError: error.message });

            toast.error(error.message);
        }
    }

    render() {
        if (process.env.NODE_ENV !== 'development') 
            return <div></div>;

        let videoSource = '';
        if(this.state.videoFileURL !== '') 
            videoSource = <source src={this.state.videoFileURL} type="video/mp4" />;

        return (
            <div>
                <ToastContainer />
                <hr />
                <select name="section" value={this.state.section} onChange={this.handleChange}>
                    <option disabled value="">-- Select Test --</option>
                    <option value="download">Download Test</option>
                    <option value="create">Create Room Test</option>
                    <option value="torrent">Torrent Test</option>
                    <option value="cloud">Cloud Select Test</option>
                </select>
                <hr />
                <div className={(this.state.section === 'download') ? '' : 'hidden'}>
                    <DragDrop 
                        uppy={this.uppy} 
                        locale={{
                            strings: {
                            // Text to show on the droppable area.
                            // `%{browse}` is replaced with a link that opens the system file selection dialog.
                            dropHereOr: "Drag and Drop file or %{browse}",
                            // Used as the label for the link that opens the system file selection dialog.
                            browse: "Browse",
                            },
                        }}
                    />
                    <StatusBar 
                        uppy={this.uppy} 
                        hideAfterFinish={false}
                        showProgressDetails
                    />
                </div>
                <div className={(this.state.section === 'create') ? '' : 'hidden'}>
                    <form autoComplete="off" onSubmit={this.createRoom}>
                        <fieldset className="create">
                            <h1>Create A Room</h1>
                            <input placeholder="Your Room Name" name="roomName" type="text" onChange={this.handleChange} value={this.state.roomName} />
                            <label className="label-sup" htmlFor="roomName">Room Name</label>
                            <input placeholder="Name of the Movie" name="movieName" type="text" onChange={this.handleChange} value={this.state.movieName} />
                            <label className="label-sup" htmlFor="movieName">Movie Name</label>
                            <button type="submit" onClick={this.createRoom}>Create Room</button> 
                        </fieldset>
                    </form>
                </div>
                <div className={(this.state.section === 'torrent') ? '' : 'hidden'}>
                    <div style={{width: '50%', float: 'left'}}>
                        <select name="downloadVideo" value={this.state.downloadVideo} onChange={this.handleChange}>
                            <option disabled value="">-- Select Test Video --</option>
                            {videos.map((video, index) => <option key={index} value={video}>{video}</option>)}
                        </select>
                        <button type="button" onClick={this.downloadTorrent}>Download Torrent</button>
                        <p style={{textAlign:'center'}}>{this.state.downloadProgress}%</p>
                        <br />
                        <div className="download-button">
                            <label className="btn" htmlFor="video">Select Movie File</label>
                            <input className="hidden" ref={input => this.selectVideoInput = input} id="video" type="file" accept={this.state.videoFileType} onChange={this.handleFileLocationChange}/>
                        </div>
                    </div>
                    <div style={{width: '50%', float: 'right'}}>
                        <video style={{width: '100%', height: 'auto', padding: '10px'}} autoPlay controls>
                            {videoSource}
                        </video>
                    </div>
                </div>
                <div className={(this.state.section === 'cloud') ? '' : 'hidden'}>
                    <div>
                        <h2>Dropbox</h2>
                        <DropboxChooser 
                            appKey={this.state.dropboxAppKey}
                            success={files => this.handleDopboxSelect(files)}
                            cancel={() => console.log('closed')}
                            multiselect={false}
                            extensions={['.mp4']} 
                        >
                            <button className="dropbox-button">Select Video on Dropbox</button>        
                        </DropboxChooser>
                        {(this.state.dropboxVideoLink === '') ? '' : 
                            <div>
                                <a rel="noopener noreferrer" href={this.state.dropboxVideoLink} target="_blank">Download {this.state.dropboxVideoName}</a>
                            </div>
                        }
                    </div>
                    <div>
                        <h2>Google Drive</h2>
                        <GooglePicker 
                            clientId={this.state.gdriveClientId}
                            developerKey={this.state.gdriveDeveloperKey}
                            scope={['https://www.googleapis.com/auth/drive.readonly']}
                            onChange={data => this.handleGDriveSelect(data.docs)}
                            onAuthFailed={data => console.log('on auth failed:', data)}
                            multiselect={false}
                            navHidden={false}
                            authImmediate={false}
                            mimeTypes={['video/mp4']} 
                        >
                            <button className="google-drive-button">Select Video on Google Drive</button>        
                        </GooglePicker>
                        {(this.state.gdriveVideoLink === '') ? '' : 
                            <div>
                                <a rel="noopener noreferrer" href={this.state.gdriveVideoLink} target="_blank">Download {this.state.gdriveVideoName}</a>
                            </div>
                        }
                    </div>
                    <div>
                        <h2>One Drive</h2>
                        {/*
                        <GraphFileBrowser 
                            getAuthenticationToken={this.getOneDriveAuthenticationToken}
                            onSuccess={keys => console.log('onSuccess', keys)}
                            onCancel={err => console.log('onCancel', err.message)} 
                            itemMode="files"
                            selectionMode="single"
                        />
                        */}
                        {(this.state.onedriveVideoLink === '') ? '' : 
                            <div>
                                <a rel="noopener noreferrer" href={this.state.onedriveVideoLink} target="_blank">Download {this.state.onedriveVideoName}</a>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Test;