import React from 'react';
import Helmet from 'react-helmet';
import Player from '../components/VideoJSPlayer';
import { ToastContainer, toast } from 'react-toastify';
import { auth } from '../services/firebase';
import { db } from '../services/firebase';
import { _updateRoomState } from '../helpers/db';
import Actions from '../components/Actions';

const URL = window.URL || window.webkitURL;

class Room extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: auth().currentUser,
            userIsOwner: false,
            roomId: '',
            room: null,
            videoFile: null,
            videoFileType: 'video/*',
            videoFileDownloading: false,
            videoFileDownloadProgress: 0,
            videoJsOptions: {sources: []},
            videoReady: false,
            writeError: ''
        };
        this.player = React.createRef();

        this.downloadVideo = this.downloadVideo.bind(this);
        this.handleRoomDeleted = this.handleRoomDeleted.bind(this);
        this.handleVideoSelect = this.handleVideoSelect.bind(this);
        this.setVideo = this.setVideo.bind(this);
        this.handleFileLocationChange = this.handleFileLocationChange.bind(this);

        this.initializeRoomState = this.initializeRoomState.bind(this);
        this.getRoomState = this.getRoomState.bind(this);
        this.updateRoomState = this.updateRoomState.bind(this);
        this.updatePlayer = this.updatePlayer.bind(this);
    }

    componentDidMount () {
        //Set Room ID
        const { id } = this.props.match.params;
        this.setState({ roomId: id });

        //Set Video Ready Check Interval
        this.videoReadyInterval = setInterval(() => this.videoReady(), 500);

        //Check Owner of Room
        const roomdb = db.ref('room/' + id);
        roomdb.once('value', (snapshot) => {
            if(snapshot.val()) {
                let room = snapshot.val();
                let fileExtension = room.movieFile.split('.').pop();
                let videoFileType = (fileExtension !== '') ? 'video/' + fileExtension : 'video/*';
                let userIsOwner = (room.owner === this.state.user.uid);
                this.setState({ room, videoFileType, userIsOwner });
            } else {
                this.setState({writeError: 'Sorry, the room you requested does not exists'});
            }
        });
    }

    //Download Video
    downloadVideo() {
        if(this.state.room.movieLink !== '' && this.state.room.movieFile !== '') {
            let fileUrl = this.state.room.movieLink;
            let fileName = this.state.room.movieFile;

            // for non-IE
            if (!window.ActiveXObject) {
                var save = document.createElement('a');
                save.href = fileUrl;
                save.target = '_blank';
                save.download = fileName;

                if ( navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
                        document.location = save.href; 
                }else{
                    var evt = new MouseEvent('click', {
                        'view': window,
                        'bubbles': true,
                        'cancelable': false
                    });
                    save.dispatchEvent(evt);
                    (window.URL || window.webkitURL).revokeObjectURL(save.href);
                }
            }
            // for IE < 11
            else if ( !! window.ActiveXObject && document.execCommand) {
                var _window = window.open(fileUrl, '_blank');
                _window.document.close();
                _window.document.execCommand('SaveAs', true, fileName || fileUrl)
                _window.close();
            }
        } else {
            toast.error('Movie information not set');
        }
    }

    //Set Video File for Player
    setVideo(videoFile) {
        const videoFileURL = URL.createObjectURL(videoFile);
        this.setState({
            videoFile,
            videoJsOptions: {
                sources: [{
                    src: videoFileURL,
                    type: 'video/mp4'
                }]
            }
        });
    }

    //Handle Room Deleted
    handleVideoSelect(roomId) {
        this.selectVideoInput.click();
    }

    //Handle Video Location Change
    handleFileLocationChange(event) {
        this.setVideo(event.target.files[0]);
    }

    //Handle Room Deleted
    handleRoomDeleted() {
        setTimeout(() => { this.props.history.push('/room'); }, 1000);
    }

    //Check if Video Ready
    videoReady() {
        if(this.state.videoFile !== null && this.player.current) {
            if(this.player.current.state.videoReady){
                this.initializeRoomState();
                this.setState({ videoReady: true });
                clearInterval(this.videoReadyInterval);
            }
        }
    }

    //DB Sync Settings
    initializeRoomState() {
        if(this.state.userIsOwner) {
            this.updatePlayer(this.state.room.state);
        } else {
            this.getRoomState();
        }
    }
    getRoomState() {
        if(this.state.roomId !== '' && !this.state.userIsOwner) {
            //Connect to DB
            const roomdb = db.ref('room/' + this.state.roomId + '/state');
            roomdb.on('value', (snapshot) => {
                let state = snapshot.val();
                let room = { ...this.state.room };
                    room.state = state;
                this.updatePlayer(state);
                this.setState({ room });
            });
        }
    }
    async updateRoomState(isPlaying) {
        this.setState({ writeError: '' });

        let startAt = (this.player.current) ? this.player.current.player.currentTime() : 1;
        let startTime = Date.now();
        let state = { isPlaying, startTime, startAt }

        try {
            await _updateRoomState(this.state.roomId, state);
        } catch (error) {
            console.log(error.message);
            this.setState({ writeError: error.message });
        }
    }
    updatePlayer(state){
        let playTime = state.startAt;
        let timeDiff = 0;

        if(state.isPlaying === true) {
            timeDiff = (Date.now() - new Date(state.startTime).getTime());
            playTime = Math.abs(timeDiff / 1000) + state.startAt;
        }
        
        this.player.current.player.currentTime(playTime);

        if(state.isPlaying === true)
            this.player.current.player.play();
        else
            this.player.current.player.pause();
    }

    //Handle Video Actions from Player
    handleVideoPlayed = () => {
        this.updateRoomState(true);
    }
    handleVideoPaused = () => {
        this.updateRoomState(false);
    }
    handleVideoSeeked = () => {
        let isPlaying = this.player.current.isPlaying();
        this.updateRoomState(isPlaying);
    }

    render() {
        if(this.state.writeError !== '')
            return <section className="room"><h1>{this.state.writeError}</h1></section>;

        let videoSourceAvailable = (this.state.videoJsOptions.sources.length > 0) ? true : false;

        return (
            <section className="room">
                {(this.state.room !== null) ?
                <Helmet>
                    <title>{this.state.room.roomName}</title>
                    <meta name="description" content={'Now Playing: ' + this.state.room.movieName} />
                </Helmet>
                : null }
                <ToastContainer />
                <div className="room-header">
                    <h1>{(this.state.room) ? this.state.room.roomName : ''}</h1>
                    <Actions 
                        roomId={this.state.roomId}
                        movieLink={(this.state.room !== null) ? this.state.room.movieLink : ''}
                        movieFile={(this.state.room !== null) ? this.state.room.movieFile : ''}
                        showDelete={this.state.userIsOwner} 
                        showDownload={true} 
                        showSelect={true} 
                        showCopy={true} 
                        roomDeleted={this.handleRoomDeleted}
                        selectVideo={this.handleVideoSelect}
                    />
                </div>
  
                {(videoSourceAvailable) ?
                <div className={'player' + ((this.state.userIsOwner) ? '' : ' viewer')}>
                    <Player 
                        ref={this.player} 
                        userIsOwner={this.state.userIsOwner} 
                        videoPlayed={this.handleVideoPlayed}
                        videoPaused={this.handleVideoPaused}
                        videoSeeked={this.handleVideoSeeked}
                        { ...this.state.videoJsOptions }
                    />
                </div> : null }
                <div className={'list-item setup' + (videoSourceAvailable ? ' hidden' : '')}>
                    <div className="col-details list-item-col">
                        <h3>{(this.state.room) ? this.state.room.movieName : ''}</h3>
                        <p>{(this.state.room) ? this.state.room.movieFile : ''}</p>
                    </div>
                    {(this.state.videoFileDownloadProgress === 0) ?
                    <div className="download list-item-col">
                        <div className="download-button">
                            <div>
                                <button type="button" onClick={this.downloadVideo}>Download Movie</button>
                                <span className="action-help">
                                    Need the Movie?
                                    <svg transform='rotate(135) translate(-3 2)' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="36px" height="36px"><path d="M0 0h24v24H0z" fill="none"/><path d="M12.5 8c-2.65 0-5.05.99-6.9 2.6L2 7v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88 3.54 0 6.55 2.31 7.6 5.5l2.37-.78C21.08 11.03 17.15 8 12.5 8z"/></svg>
                                </span>
                            </div>
                            <span className="spacer"></span>
                        </div>
                        <div className="download-button">
                            <label className="btn" htmlFor="video">Select Movie File</label>
                            <input className="hidden" ref={input => this.selectVideoInput = input} id="video" type="file" accept={this.state.videoFileType} onChange={this.handleFileLocationChange}/>
                            <span className="help">
                                Already Downloaded the Movie?
                                <svg transform='rotate(135) translate(-3 2)' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="36px" height="36px"><path d="M0 0h24v24H0z" fill="none"/><path d="M12.5 8c-2.65 0-5.05.99-6.9 2.6L2 7v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88 3.54 0 6.55 2.31 7.6 5.5l2.37-.78C21.08 11.03 17.15 8 12.5 8z"/></svg>
                            </span>
                        </div>
                    </div>
                    :
                    <div className="download list-item-col">
                        <div className="download-button">
                            <div className="download-progress">
                                <div className="download-progress-bar" style={{width: this.state.videoFileDownloadProgress + '%'}}></div>
                                <p className="download-progress-percent">{this.state.videoFileDownloadProgress}%</p>
                            </div>
                            <span className="help">Downloading...</span>
                        </div>
                    </div>
                    }
                </div>
            </section>
        );
    }
}

export default Room;