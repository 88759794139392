import React from 'react';
import Helmet from 'react-helmet';
import { Route, BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { auth } from './services/firebase';

import Nav from './components/Nav';
import Home from './pages/Home';
import Signup from './pages/Signup';
import Login from './pages/Login';
import Room from './pages/Room';
import CreateRoom from './pages/CreateRoom';
import Rooms from './pages/Rooms';

import Test from './pages/Test';

function PrivateRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => authenticated === true
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
    />
  )
}
function PublicRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => <Component {...props} />}
    />
  )
}

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      authenticated: false,
      loading: true,
    };
  }

  componentDidMount() {
    auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          authenticated: true,
          loading: false,
        });
      } else {
        this.setState({
          authenticated: false,
          loading: false,
        });
      }
    })
  }

  render() {
    return this.state.loading === true ? <h2>Loading...</h2> : (
      <Router>
        <Helmet>
            <title>Social Distancing Movies</title>
            <meta name="description" content="Watch movies with your friends the Covid-19 free way" />
        </Helmet>
        <header>
          <Nav authenticated={this.state.authenticated} />
        </header>
        <Switch>
          <PublicRoute path="/signup" authenticated={this.state.authenticated} component={Signup}></PublicRoute>
          <PublicRoute path="/login" authenticated={this.state.authenticated} component={Login}></PublicRoute>
          <PrivateRoute path="/create" authenticated={this.state.authenticated} component={CreateRoom}></PrivateRoute>
          <PrivateRoute path="/room/:id" authenticated={this.state.authenticated} component={Room}></PrivateRoute>
          <PrivateRoute path="/room" authenticated={this.state.authenticated} component={Rooms}></PrivateRoute>
          <Route path="/test" authenticated={this.state.authenticated} component={Test}></Route>
          <Route path="/" authenticated={this.state.authenticated} component={Home}></Route>
        </Switch>
      </Router>
    );
  }
}

export default App;