import React from 'react';
import { Link } from "react-router-dom";
import { _logout } from '../helpers/auth';

class Nav extends React.Component {
    render() {
        return (
            <nav className="nav" role="navigation">
                <Link to="/"><h1 className="logo">{process.env.REACT_APP_LNAME}</h1></Link>
                {(this.props.authenticated) ?
                <ul>
                    <li><Link to="/create">Create Room</Link></li>
                    <li><Link to="/room">My Rooms</Link></li>
                    <li><a className="nav-last" href="/login" onClick={() => _logout()}>Logout</a></li>
                </ul>
                :
                <ul>
                    <li><Link className="nav-last" to="/login">Login</Link></li>
                </ul>
                }
            </nav>
        );
    }
}

export default Nav;